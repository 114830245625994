import { createTRPCProxyClient, httpBatchLink, type inferRouterProxyClient } from "@trpc/client";
import type { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from "@metly/api";
import { createTRPCReact } from "@trpc/react-query";

export const trpc = createTRPCReact<AppRouter>();

export function serverTrpc(request: Request) {
	return createTRPCProxyClient<AppRouter>({
		links: [
			httpBatchLink({
				// biome-ignore lint/style/noNonNullAssertion: <explanation>
				url: process.env.API_URL || 'http://localhost:5001/trpc',
				headers() {
					return {
						cookie: request.headers.get('cookie') ?? '',
						connection: "keep-alive"
					};
				},
				fetch(url, options) {
					const req = fetch(url, {
						...options,
						credentials: 'include',
					});
					return req;
				},
			}),
		],
	})
}

export type RouterOutputs = inferRouterOutputs<AppRouter>;
export type RouterInputs = inferRouterProxyClient<AppRouter>;

export type ProductSearchOut = RouterOutputs['product']['search']['data'][0]["product"];
export type ProductBookmark = RouterOutputs['product']['search']['data'][0]["bookmark"];

export type ProductSearch = RouterOutputs['product']['search'];

export type Project = RouterOutputs['project']['list'][0];

export type ProductWithBookmark = RouterOutputs['product']['findById'];
export type Opportunity = RouterOutputs['opportunity']['findById'];

export type OpportunityWithProduct = RouterOutputs['opportunity']['findWithProduct'][0];

export type ProjectWithOpportunity = RouterOutputs['project']['listWithOpportunities'][0];

export type TeamUser = RouterOutputs['user']['getTeam'][0];

export type Report = RouterOutputs['report']['list'][0];
export type ReportSection = RouterOutputs['report']['listWithSections'][0]['sections'][0];